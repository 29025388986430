@use 'abstracts' as *;
@use 'ag-grid-enterprise/styles' as ag;

@include ag.grid-styles(
  (
    theme: carbon-alt-delete,
    extend-theme: quartz,

    border-color: clr(grayscale-200),
    row-hover-color: clr(grayscale-100),
    checkbox-checked-color: clr(primary-700),
    header-background-color: clr(white),
    range-selection-border-color: clr(primary-700),
    active-color: clr(primary-600)
  )
);

.ag-theme-carbon-alt-delete {
  --ag-wrapper-border-radius: 0;
}

ag-grid-angular {
  .ag-cell-wrapper,
  .ag-cell-wrapper.ag-row-group {
    height: 100%;
    align-items: center;
  }

  .ag-ltr {
    .ag-sort-ascending-icon,
    .ag-sort-descending-icon {
      padding-left: size(2);
    }
    .ag-sort-order {
      padding-left: size(16);
    }
  }
}

.cad-ag-grid--filters-active {
  .ag-side-buttons {
    .ag-side-button:last-child {
      > *,
      .ag-icon {
        color: clr(green-100);
        font-weight: 500;
      }
    }
  }
}

.cad-ag-grid-footer {
  display: flex;
  align-items: center;
  border: 1px solid var(--ag-border-color);
  border-top: none;
  border-bottom-left-radius: var(--ag-border-radius);
  border-bottom-right-radius: var(--ag-border-radius);
  padding-inline: var(--ag-cell-horizontal-padding);

  .cad-ag-grid-footer-actions {
    display: flex;
    align-items: center;
    gap: size(16);
    margin-inline-end: auto;
  }
}
